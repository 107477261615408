


















































































































































































import MyPbxToolsApi from "@/common/MyPbxToolsApi.ts";
import {Component, Vue} from "vue-property-decorator";
import EditDialog from "@/components/EditDialog.vue";
import UserData from "@/common/data/UserData";
import Password from "@/components/Password.vue";
import InstanceForm from "@/components/pb/InstanceForm.vue";
import BaseInstance from "@/pb/data/BaseInstance";
import Instance from "@/pb/data/Instance";
import PopslessSetup from "@/pb/data/PopslessSetup";
import SetupInstance from "@/pb/data/SetupInstance";

const POPS_CONFIG: PopslessSetup = {
    tcxPort: -1,
    tcxPassword: "",
    tcxUsername: "",
    backendId: -1,
    showPw: true
};
@Component({
    components: {InstanceForm, Password, EditDialog}
})
export default class PowerboardInstanceManager extends Vue {
    instances: SetupInstance[] = [];
    users: UserData[] = [];
    loading: boolean = false;
    attemptDeleteTarget: Instance | null = null;
    showPassword: boolean = false;
    currPassword: string = "";
    showCreate: boolean = false;
    selectedInstance: Instance | null = null;
    hostedPhrase = "Hosted";

    prototype: BaseInstance | { setup: PopslessSetup } = {
        licenceKey: "N/A",
        dbClearInterval: "N/A",
        password: undefined,
        callsFilter: "N/A",
        name: "N/A",
        ownerId: -1,
        ownerName: "N/A",
        fqdn: "N/A",
        passwordSha512: "N/A",
        token: "N/A",
        setup: {
            backendId: -1,
            tcxPassword: "",
            tcxPort: 443,
            tcxUsername: "admin"
        }
    };
    headers: any[] = [
        {text: "ID", value: "id", align: 'right'},
        {text: 'Owner', value: 'ownerName'},
        {text: 'Licence Key', value: 'licenceKey'},
        {text: 'Name', value: 'name'},
        {text: 'FQDN', value: 'fqdn'},
        {text: '3CX Port', value: 'setup.tcxPort'},
        {text: '3CX Username', value: 'setup.tcxUsername'},
        {text: '3CX Password', value: 'setup.tcxPassword'},
        {text: '', value: 'actions', align: 'right'}
    ]

    get api(): MyPbxToolsApi {
        return MyPbxToolsApi.instance;
    }

    get passwordTitle(): string {
        return `Password for ${this.selectedInstance?.name}`;
    }

    get attemptDelete(): boolean {
        return this.attemptDeleteTarget !== null;
    }

    set attemptDelete(attempt: boolean) {
        if (!attempt)
            this.attemptDeleteTarget = null;
    }

    mounted() {
        this.reloadDatas();
    }

    reloadDatas() {
        this.getDatas().catch(err => console.error(err));
    }

    async getDatas() {
        this.loading = true;
        const rawInstances = await MyPbxToolsApi.instance.doWbActionGeneric<Instance[]>('instances', 'list');
        this.instances = await Promise.all(rawInstances.map(async m => {

            if (m.isPops) {
                return {...m, setup: POPS_CONFIG};
            }

            const setup = await MyPbxToolsApi.instance.doWbActionGeneric<PopslessSetup>("instance_popsless", 'get', {backendId: m.id});

            setup.showPw = false;
            return {
                ...m,
                setup: setup
            };
        }));

        this.users = await MyPbxToolsApi.instance.listUsers();
        this.loading = false;
    }

    async updatePasswordAsync(instance: Instance, pw: string) {

        await this.updateItem({id: instance.id, password: pw} as unknown as Instance);
    }

    updatePassword(instance: Instance, pw: string) {
        this.updatePasswordAsync(instance, pw).catch(console.error);
    }

    async deleteItem(wb: Instance) {
        await MyPbxToolsApi.instance.doWbActionGeneric('instances', 'delete', {id: wb.id});
        this.instances = this.instances.filter(b => b.id !== wb.id);
    }

    async createItemAsync(i: BaseInstance) {

        const item = await MyPbxToolsApi.instance.doWbActionGeneric<SetupInstance>('instances', 'create', i);
        this.instances.push(item);
    }

    createItem(i: BaseInstance) {
        this.createItemAsync(i).catch(console.error);
    }

    async updateItem(wb: Instance) {
        const pw = wb?.password;
        const data: Partial<Instance> = {
            id: wb.id,
            name: wb.name,
            ownerId: wb.ownerId,
            licenceKey: wb.licenceKey,
            fqdn: wb.fqdn
        };
        if (pw && pw !== "...") {
            data.password = wb.password;
        }
        await MyPbxToolsApi.instance.doWbActionGeneric('instances', 'update', data);

    }

    toggleShowPw(wb: PopslessSetup) {
        wb.showPw = !wb.showPw;
    }

    async updateSetup(wb: SetupInstance) {
        if (wb.isPops)
            return;
        const s = wb.setup;

        const data: Partial<PopslessSetup> = {
            backendId: wb.id,
            tcxUsername: s.tcxUsername,
            tcxPassword: s.tcxPassword,
            tcxPort: s.tcxPort,
        };
        await MyPbxToolsApi.instance.doWbActionGeneric('instance_popsless', 'set', data);

    }
}
