




























import Component from "vue-class-component";
import Vue from "vue";
import {Emit, ModelSync, Prop} from "vue-property-decorator";
import BaseInstance from "@/pb/data/BaseInstance";

@Component
export default class PowerboardForm extends Vue {
    @Prop({type: String})
    title!: string;

    @ModelSync('show', 'update:show', {type: Boolean})
    shouldShow!: boolean;

    @ModelSync('modelValue', 'update:modelValue', {type: Object})
    prototype!: BaseInstance;

    @Emit()
    submit(prototype: BaseInstance) {
        console.log('item submitted', prototype);
    }
}
