var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto"},[_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.reloadDatas}},[_vm._v(" Refresh "),_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary"},on:{"click":function($event){_vm.showCreate=true}}},'v-btn',attrs,false),on),[_vm._v(" Create "),_c('v-icon',[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.showCreate),callback:function ($$v) {_vm.showCreate=$$v},expression:"showCreate"}},[_c('instance-form',{attrs:{"show":_vm.showCreate,"title":"Create Instance"},on:{"update:show":function($event){_vm.showCreate=$event},"submit":function($event){return _vm.createItem(_vm.prototype)}},model:{value:(_vm.prototype),callback:function ($$v) {_vm.prototype=$$v},expression:"prototype"}})],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.instances,"loading":_vm.loading},scopedSlots:_vm._u([{key:"header.licenceKey",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',[_vm._v("mdi-license")])]}},{key:"header.fqdn",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',[_vm._v("mdi-link")])]}},{key:"header.ownerName",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',[_vm._v("mdi-account")])]}},{key:"header.setup.tcxPassword",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',[_vm._v("mdi-form-textbox-password")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('edit-dialog',{on:{"update":function($event){return _vm.updateItem(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":""},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.ownerName",fn:function(ref){
var item = ref.item;
return [_c('edit-dialog',{attrs:{"return-value":item.ownerId},on:{"update:returnValue":function($event){return _vm.$set(item, "ownerId", $event)},"update:return-value":function($event){return _vm.$set(item, "ownerId", $event)},"update":function($event){return _vm.updateItem(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-select',{attrs:{"items":_vm.users,"item-text":"name","item-value":"id"},model:{value:(item.ownerId),callback:function ($$v) {_vm.$set(item, "ownerId", $$v)},expression:"item.ownerId"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.ownerName)+" ")])]}},{key:"item.licenceKey",fn:function(ref){
var item = ref.item;
return [_c('edit-dialog',{on:{"update":function($event){return _vm.updateItem(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":""},model:{value:(item.licenceKey),callback:function ($$v) {_vm.$set(item, "licenceKey", $$v)},expression:"item.licenceKey"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.licenceKey)+" ")])]}},{key:"item.fqdn",fn:function(ref){
var item = ref.item;
return [_c('edit-dialog',{on:{"update":function($event){return _vm.updateItem(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":""},model:{value:(item.fqdn),callback:function ($$v) {_vm.$set(item, "fqdn", $$v)},expression:"item.fqdn"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.fqdn)+" ")])]}},{key:"item.setup.tcxPort",fn:function(ref){
var item = ref.item;
return [_c('edit-dialog',{on:{"update":function($event){return _vm.updateSetup(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [(!item.isPops)?_c('v-text-field',{attrs:{"label":"Edit","single-line":"","type":"number"},model:{value:(item.setup.tcxPort),callback:function ($$v) {_vm.$set(item.setup, "tcxPort", $$v)},expression:"item.setup.tcxPort"}}):_vm._e()]},proxy:true}],null,true)},[(item.isPops)?[_vm._v(" "+_vm._s(_vm.hostedPhrase)+" ")]:[_vm._v(" "+_vm._s(item.setup.tcxPort)+" ")]],2)]}},{key:"item.setup.tcxUsername",fn:function(ref){
var item = ref.item;
return [_c('edit-dialog',{on:{"update":function($event){return _vm.updateSetup(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [(!item.isPops)?_c('v-text-field',{attrs:{"label":"Edit","single-line":""},model:{value:(item.setup.tcxUsername),callback:function ($$v) {_vm.$set(item.setup, "tcxUsername", $$v)},expression:"item.setup.tcxUsername"}}):_vm._e()]},proxy:true}],null,true)},[(item.isPops)?[_vm._v(" "+_vm._s(_vm.hostedPhrase)+" ")]:[_vm._v(" "+_vm._s(item.setup.tcxUsername)+" ")]],2)]}},{key:"item.setup.tcxPassword",fn:function(ref){
var item = ref.item;
return [_c('edit-dialog',{on:{"update":function($event){return _vm.updateSetup(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [(!item.isPops)?_c('v-text-field',{attrs:{"append-icon":item.setup.showPw ? 'mdi-eye': 'mdi-eye-off',"type":item.setup.showPw ? 'text': 'password',"label":"Edit","single-line":""},on:{"click:append":function($event){return _vm.toggleShowPw(item.setup)}},model:{value:(item.setup.tcxPassword),callback:function ($$v) {_vm.$set(item.setup, "tcxPassword", $$v)},expression:"item.setup.tcxPassword"}}):_vm._e()]},proxy:true}],null,true)},[(item.isPops)?[_vm._v(" "+_vm._s(_vm.hostedPhrase)+" ")]:_c('v-text-field',{attrs:{"append-icon":item.setup.showPw ? 'mdi-eye': 'mdi-eye-off',"type":item.setup.showPw ? 'text': 'password',"label":"Edit","readonly":"","single-line":""},on:{"click:append":function($event){return _vm.toggleShowPw(item.setup)}},model:{value:(item.setup.tcxPassword),callback:function ($$v) {_vm.$set(item.setup, "tcxPassword", $$v)},expression:"item.setup.tcxPassword"}})],2)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-spacer'),_c('v-tooltip-btn',{attrs:{"btn-icon":"mdi-form-textbox-password","hint":"Change Password","icon-color":"primary"},on:{"click":function($event){_vm.showPassword = true; _vm.selectedInstance = item}}}),_c('v-tooltip-btn',{attrs:{"btn-icon":"mdi-delete","hint":"Delete","icon-color":"secondary"},on:{"click":function($event){_vm.attemptDeleteTarget=item}}})],1)]}}])}),_c('v-dialog',{model:{value:(_vm.showPassword),callback:function ($$v) {_vm.showPassword=$$v},expression:"showPassword"}},[_c('password',{attrs:{"show":_vm.showPassword,"title":_vm.passwordTitle},on:{"update:show":function($event){_vm.showPassword=$event},"submit":function($event){return _vm.updatePassword(_vm.selectedInstance, _vm.currPassword)}},model:{value:(_vm.currPassword),callback:function ($$v) {_vm.currPassword=$$v},expression:"currPassword"}})],1),_c('v-dialog',{model:{value:(_vm.attemptDelete),callback:function ($$v) {_vm.attemptDelete=$$v},expression:"attemptDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v("Are you sure you want to delete the Instance "+_vm._s(_vm.attemptDeleteTarget == null ? 'null' : _vm.attemptDeleteTarget.name)+"? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){_vm.deleteItem(_vm.attemptDeleteTarget); _vm.attemptDelete = false;}}},[_vm._v(" Confirm "),_c('v-icon',[_vm._v("mdi-check")])],1),_c('v-btn',{attrs:{"color":"warning"},on:{"click":function($event){_vm.attemptDelete = false}}},[_vm._v(" Cancel "),_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }